@import "../../styles/variables";


.toolt{
  position: fixed;
  z-index: 100;
  width: 120px;
  background-color: rgba($color: #000000, $alpha: 0.8);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  left: 60px;
}
.toolt::after {
  content: "";
  position: absolute;
  top: 35%;
  left: -4px;
  transform: rotate(90deg);
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.root {
  position: fixed;
  
  z-index: 2;
  width: 210px;
  max-width: 210px;
  left: 0;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  overflow: auto;

  padding: 20px 24px;

  display: flex;
  flex-direction: column;

  background-color: $gray-6;
  box-shadow: 1px 0px 0px #eeeeee;
  overflow: hidden;

  transition: all 250ms ease-in-out;
  transition-delay: 250ms;

  &.--narrow {
    padding: 20px 12px;
    max-width: 69px;
    .logo {
      margin-left: 10px;
    }

    .text {
      visibility: hidden;
    }

    .menu_item {
      
      > a {
        padding: 0 11px;
        max-width: 45px;

      }
    }
    

    .submenu {
      max-height: 0;
    }

    .user {
      height: 45px;
      width: 45px;
      padding: 4px;
      justify-content: center;
      margin: 0;

      transition: all 250ms ease-in-out;
      transition-delay: 500ms;

      &__details {
        display: none;
        max-width: 0;
        overflow: hidden;
      }
    }
  }
}

.logo {
  width: 20px;
  transition: all 250ms ease-in-out;
  transition-delay: 250ms;
  cursor: pointer;
}
.logoActive{
  rotate: 90deg;
  transform: translate(-20px, -70px)
}

.nav {
  padding: 30px 0;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgba($color: #000000, $alpha: 0.8);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 100;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}
.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.nav_padding {
  flex-grow: 1;
  flex-basis: 0;
  max-height: 90px;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.menu_item:hover .tooltiptext {
  visibility: visible;
}
.menu_item {
  width: 100%;

  > a {
    
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 14px;
    height: 35px;
    color: $primary;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    transition: all 250ms ease-in-out;

    &.active {
      background-color: $status-default;

      color: $white;

      path[fill="black"],
      circle[fill="black"],
      path[fill="#222222"] {
        fill: $white;
      }

      path[stroke="black"],
      circle[stroke="black"],
      circle[stroke="#222222"],
      ellipse[stroke="#222222"],
      path[stroke="#222222"] {
        stroke: $white;
      }
    }
  }

  & + & {
    margin-top: 10px;
  }

  &__icon {
    margin-right: 12px;
  }
}

.submenu {
  margin: 0 0 0 36px;
  padding: 0;
  list-style: none;
  overflow: hidden;
  max-height: 200px;

  transition: max-height 250ms ease-out;

  &__item {
    padding: 4px 0;
    white-space: nowrap;

    &:first-child {
      margin-top: 10px;
    }

    a {
      display: inline-flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: $primary-l;
      text-decoration: none;

      &:before {
        content: "";
        width: 8px;
        height: 8px;
        background: #c4c4c4;
        border-radius: 8px;
        margin-right: 12px;
      }

      &.--active {
        color: #000000;

        &::before {
          background: $primary;
        }
      }
    }
  }

  &.--hidden {
    max-height: 0;
  }
}

.user_wrapper {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
}

.user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  margin-right: -16px;
  margin-left: -16px;
  padding: 4px 4px 4px 16px;
  border-radius: 48px;
  cursor: pointer;

  transition: all 250ms ease-in-out;
  transition-delay: 500ms;

  &:hover {
    background-color: rgba($color: $primary-low, $alpha: 0.5);
  }

  &:active {
    background-color: $primary-low;
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100px;
  }

  &__name {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 2px;
  }

  &__email {
    font-size: 12px;
    line-height: 14px;

    color: #000000;
  }

  &__avatar {
    object-fit: cover;
    height: 40px !important;
    width: 40px !important;
    margin-right: 0 !important;
    border-radius: 40px;
  }
}

.menu_top{
  display: none;
}
.topLogo{
  margin-top: 28px;
  margin-left:-10px;
}

.backdrop{
  display: none;
}

@media screen and (max-width: 768px) {
  .menu_top{
    display: flex;
    height: 70px;
    width: 100%;
    background-color: $gray-6;
    box-shadow: 1px 0px 0px #eeeeee;
    position: fixed;
    z-index: 3;
    align-items: center;
    padding: 0 24px;
  }
  .backdrop{
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.3);
  }
  .root {
    width: 70vw !important;
    max-width: 70vw !important;
    padding: 20px 20px;
    display: flex;
    transition-delay: 0ms;
    .logo {
      display: none !important;
    }
    .user{
      transition-delay: 0ms;
    }
    &.--narrow {
      padding: 0px;
      width: 0px !important;
      max-width: 0px !important;
    }
  }
  .toolt{
    display: none;
  }
}


