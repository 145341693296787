@import "../../styles/variables";

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.title_wrapper {
  display: flex;
  align-items: center;
}

.title {
  margin-right: 28px;
}

.subtitle {
  color: $primary-l;
  margin-left: 70px;
}

.buttons {
  display: flex;

  .complete_btn {
    margin-right: 26px;
  }
}

.content {
  display: flex;
  margin-top: 34px;
}

.main_content {
  flex-grow: 1;
  padding-right: 10px;
}

.drugs {
  flex-basis: 340px;
  flex-grow: 0;
}

.info {
  position: relative;
  padding-bottom: 14px;

  &:not(:last-child) {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 12px;
  }

  &.--borderless {
    border-bottom: none;
  }
}
.horizont {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
}


.identification_card {
  position: absolute;
  top: 26px;
  right: 0;
  opacity: 1;
  transition: .2s;

  &:hover {
    opacity: .5;
  }
}

.insurance {
  display: flex;
  align-items: center;
  height: 48px;

  &__logo {
    max-width: 56px;
    margin-right: 20px;
  }

  &__edit_wrapper {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    padding: 0 5px;
  }

  &__edit {
    color: $primary-l;
  }
}

.drug {
  position: relative;
  padding-bottom: 14px;
  border-bottom: 1px solid #eeeeee;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &__name {
    margin-bottom: 7px;
  }
}

.drugActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.otc {
  position: relative;
  padding-bottom: 14px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &__name {
    margin-bottom: 7px;
  }
}

.payments {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 10px;
}

.payment {
  display: flex;
  flex-direction: column;

  &__label {
    margin-bottom: 5px;
  }

  &__value {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
  }
}

.notify_action {
  display: block;
  width: 100%;
  margin-top: 20px;
  margin: 0 3px;
}
.address_button {
  width: 100%;
  border-width: 0px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
}
.delivery_address {
  text-align: left;
}
.list_wrapper {
  min-width: 550px;
  min-height: 200px;
  max-height:80vh;
  overflow-y: scroll;
}
.modalDesc {
  margin-top: 50px;
}
.optionWrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
.container {
  cursor: pointer;
  margin-top: 10px;
  padding: 7px;
}
.radioButton {
  size: 40px;
}
.radioButtonText {
  margin-left: 5px;
  font-size: larger;
  margin: 4px;
  margin-left: 10px;
}
.actions {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 40px;
}
.row{
  display: flex;
}
.duration{
  transform: rotate(-90deg) translateX(-60px) translateY(-5px);
  height: 15px;
  width: 20px;
  margin: 0;
  padding: 0;
}
.statuses{
  margin-top: 10px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.text{
  color: #000;
  font-weight:600;
  margin-left: 5px;
}
.unit_address{
  font-size: 16px;
}

.label {
  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: 17px;
  color: $primary-l;

  margin-bottom: 5px;
}

.calendarRoot{
  width: 142px;
}

.times{
  display: flex;
  justify-content: space-between;
}
.select {
  width: 140px;
}
.addAddress>p{
  padding: 0;
  margin: 0;
}
.addAddress{
  font-weight: 600;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  background-color: desaturate(darken($gray-6, 1), 4);
  border-radius: 3px;
  font-weight: 600;
  letter-spacing: .05em;
  cursor: pointer;
  transition: .2s;
  gap: 10px;
  &:hover {
    background-color: desaturate(darken($gray-6, 7), 8);
  }
}
.actionWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.cancelButton {
  margin-right: 15px;
}
.googleInput{
  margin-top: 2px;
}

.column{
  display: block;
  flex-direction: column;
}
.radioOption {
  cursor: pointer;
  margin-top: 10px;
  padding: 7px;
  display: flex;
  border-radius: 8px;
  border: 1px solid  $gray-2;
}

.card{
  margin-top: 20px;
  padding: 10px 12px;

  border-radius: 3px;
  transition: .2s;
  background-color: desaturate(darken($gray-6, 1), 4);
}

.card:hover {
  background-color: desaturate(darken($gray-6, 7), 8);
}

.item_header {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  letter-spacing: .05em;
  margin-bottom: 15px;
}

.row{
  display: flex;
  justify-content: space-between;
}
.wrongAddress{
  color: #EF233C;
  font-size: 13px;
}
.item_header {
  padding: 10px 12px;
  background-color: desaturate(darken($gray-6, 1), 4);
  border-radius: 3px;
  font-weight: 600;
  letter-spacing: .05em;
  cursor: pointer;
  transition: .2s;

  &:hover {
    background-color: desaturate(darken($gray-6, 7), 8);
  }
}
.icon_rotated{
  transform: rotate(-90deg);
}
.arror_icon{
  transform: rotate(90deg);
}

@media screen and (max-width: 768px) {
  .content {
    flex-direction: column;
    gap: 12px;
  }

  .main_content {
    padding-right: 0;
  }

  .drugs {
    flex-basis: 100%;
  }

  
}